/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "Audio2Sign",
            "endpoint": "https://z9h9o5zceb.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:70997bd0-a3c4-4f32-af99-3cb8d46492dd",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_Yi1jOvtS9",
    "aws_user_pools_web_client_id": "1d2cc3kthdgnimgpr8r1dmi6h0",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "genasl-data",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
